<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="4"
      >
        <statistics-card-with-images
          class="fill-height"
          :icon="ratingsOptions.icon"
          :avatar="ratingsOptions.avatar"
          :avatar-width="ratingsOptions.avatarWidth"
          :chip-color="ratingsOptions.chipColor"
          :chip-text="ratingsOptions.chipText"
          :statistics="totalAmountList"
          :stat-title="ratingsOptions.statTitle"
        ></statistics-card-with-images>
      </v-col>
      <v-col
        cols="12"
        sm="8"
      >
        <v-card class="fill-height">
          <v-card-title>Suchen</v-card-title>
          <v-card-text>
            <div class="d-flex align-center pb-5">
              <v-text-field
                v-model="filter.searchString"
                dense
                outlined
                hide-details
                clearable
                placeholder="Zielgruppe suchen"
                class="channel-list-search me-3"
              ></v-text-field>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card id="channel-list">
          <v-card-text class="d-flex align-center flex-wrap pb-0">

            <div class="d-flex align-center pb-5">
              <router-link :to="'/zielgruppe/erstellen'" >
                <v-btn
                  color="accent"
                  class="me-3"
                >
                  <v-icon size="30" class="pr-2">{{icons.mdiBullseyeArrow }}</v-icon>
                  <span>Zielgruppe erstellen</span>
                </v-btn>
              </router-link>
            </div>

            <div class="d-flex align-center pb-5">
              <template v-if="userData.avvAkzeptiert">
                <router-link :to="'/zielgruppe/hochladen'" >
                  <v-btn
                    color="primary"
                    class="me-3"
                  >
                    <v-icon size="30" class="pr-2">{{icons.mdiCloudUpload }}</v-icon>
                    <span>Eigene Adressen hochladen</span>
                  </v-btn>
                </router-link>
              </template>
              <template v-else>
                <v-btn
                  color="primary"
                  class="me-3"
                  @click="openAvvDialog"
                >
                  <v-icon size="30" class="pr-2">{{icons.mdiCloudUpload }}</v-icon>
                  <span>Eigene Adressen hochladen</span>
                </v-btn>
              </template>
            </div>

            <v-spacer></v-spacer>
            <div class="d-flex align-center pb-5">
              <v-btn
                color=""
                class="me-3"
                @click="enterReleaseCodeTargetGroup"
              >
                <v-icon size="30" class="pr-2">{{icons.mdiNumeric }}</v-icon>
                <span>Freigabecode eingeben</span>
              </v-btn>
            </div>

          </v-card-text>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="listTargetGroups"
          :options.sync="options"
          :server-items-length="totalAmountList"
          :items-per-page="options.itemsPerPage"
          :loading="loading"
          :search="filter.searchString"
          class="text-no-wrap"
          no-data-text="Keine Einträge vorhanden"
          no-results-text="Ihre Suche ergab keinen Treffer"
          :footer-props="{
            itemsPerPageText: 'Anzahl pro Seite',
            itemsPerPageAllText: 'Alle',
            pageText: '{0}-{1} von {2}',
            itemsPerPageOptions: [5,10,25,50,-1]
          }"
          :item-class="highlightRow"
        >
          <template
            v-slot:top
          >
            <v-dialog
              v-model="dialogDelete"
              width="500px"
            >
              <v-card>

                <v-toolbar flat>
                  <v-toolbar-title>Möchten Sie {{ deleteItem.titel }}&nbsp;wirklich löschen</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="closeDelete">
                    <v-icon size="18" >
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-btn>
                </v-toolbar>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="deleteTargetGroupConfirm"
                  >
                    Löschen
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="closeDelete"
                  >
                    Abbrechen
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              v-model="dialogEnterReleaseCode"
              width="500px"
            >
              <v-card>

                <v-toolbar flat>
                  <v-toolbar-title>Zielgruppen-Code eingeben</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="closeEnterReleaseCode">
                    <v-icon size="18" >
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-btn>
                </v-toolbar>

                <v-card-text>
                  <v-text-field
                    v-model="releaseCode"
                    label="Code eingeben"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    :error-messages="errorMessages.releaseCode"
                  ></v-text-field>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="enterReleaseCodeTargetGroupConfirm"
                  >
                    Absenden
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="closeEnterReleaseCode"
                  >
                    Abbrechen
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              v-model="dialogRelease"
              width="600px"
            >
              <v-card >

                <v-toolbar flat>
                  <v-toolbar-title>Freigabecode</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="closeRelease">
                    <v-icon size="18" >
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-btn>
                </v-toolbar>

                <v-card-title class="d-flex align-center justify-center pb-5">
                  {{releaseItem.freigabeCode}}
                  <v-btn icon @click="copyReleaseCode">
                    <v-icon size="18" >
                      {{ icons.mdiContentCopy }}
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text v-if="releaseItem.datenquelle == 'fremderUpload'">
                    Datenquelle: {{releaseItem.customerDisplayName}}
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="copyReleaseCode"
                  >
                    Kopieren
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="closeRelease"
                  >
                    Abbrechen
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>


            <v-dialog
              v-model="dialogAvv"
              width="1200px"
            >
              <v-card>

                <v-toolbar flat>
                  <v-toolbar-title>AV-Vertrag</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="closeAvv">
                    <v-icon size="18" >
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-btn>
                </v-toolbar>
                <avv-contract
                  :userData="userData"
                  @closeAvv="closeAvv"
                />
              </v-card>
            </v-dialog>
          </template>
          <template #[`item.idPtZielgruppe`]="{item}">
            #{{ item.idPtZielgruppe }}
          </template>
          <template #[`item.freigabeCode`]="{item}">

            <span v-if="item.freigabeCode">

              <info-popup title="">

                <template v-if="item.datenquelle == 'eigenerUpload'">
                  Genutzt von:

                  <v-simple-table class="mt-5">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left text-uppercase">Kunde</th>
                        <th class="text-left text-uppercase">Kampagnen</th>
                        <th class="text-left text-uppercase">Empfänger</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="assignedCustomer in item.assignedCustomers" :key="assignedCustomer.idPtKunde">
                        <td class="text-left">{{assignedCustomer.customerDisplayName}}</td>
                        <td>{{assignedCustomer.anzahlKampagnen}}</td>
                        <td>{{assignedCustomer.anzahlEmpfaenger}}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                </template>

                <template v-else>
                  Freigabe-Code: {{item.freigabeCode}}
                </template>
              </info-popup>

            </span>
          </template>
          <template #[`item.datenquelleDisplayName`]="{item}">
            <span class="d-flex flex-wrap-reverse align-center">
              <span class="mr-3">
                  <v-icon size="18" v-if="item.datenquelle == 'bestandsdaten'">
                  {{ icons.mdiBullseyeArrow }}
                </v-icon>
                <v-icon size="18" v-if="item.datenquelle == 'eigenerUpload'">
                  {{ icons.mdiCloudUpload }}
                </v-icon>
                <v-icon size="18" v-if="item.datenquelle == 'fremderUpload'" >
                  {{ icons.mdiShareVariantOutline }}
                </v-icon>
              </span>
              {{item.datenquelleDisplayName}}
              <info-popup title="Datenschutzerklärung der Datenquelle" v-if="item.datenquelle == 'fremderUpload'">
                {{item.datenschutzText}}
              </info-popup>
            </span>
          </template>
          <template #[`item.actions`]="{item}">

            <div v-if="item.datenquelle == 'fremderUpload'">
              <span v-if="item.angabeGeloescht">GELOESCHT</span>
            </div>
            <div v-else>
              <v-tooltip
                bottom
                v-if="item.datenquelle == 'eigenerUpload' && !item.freigabeCode"
              >
                <template #activator="{ on, attrs }">

                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="releaseTargetGroup( item )"
                  >
                    <v-icon size="18">
                      {{ icons.mdiShareVariantOutline }}
                    </v-icon>
                  </v-btn>

                </template>
                <span>Für Dritte freigeben</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="item.datenquelle == 'eigenerUpload'"
              >
                <template #activator="{ on, attrs }">
                  <a
                    :href="`/api/zielgruppe/?aktion=downloadTargetGroupAsCsv&mainData[idPtZielgruppe]=${item.idPtZielgruppe}`"
                    :download="`${item.eigenerUploadIndex}.csv`"
                  >
                    <v-btn icon small v-bind="attrs" v-on="on">
                      <v-icon size="18">{{ icons.mdiDownload }}</v-icon>
                    </v-btn>
                  </a>
                </template>
                <span>Download CSV</span>
              </v-tooltip>
              <template v-if="!item.entityLocked">
                <v-tooltip
                  bottom
                  v-if="item.datenquelle == 'bestandsdaten'"
                >
                  <template #activator="{ on, attrs }">
                    <router-link :to="'/zielgruppe/editieren/'+item.idPtZielgruppe" >
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="18">
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                      </v-btn>
                    </router-link>
                  </template>
                  <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteTargetGroup( item )"
                    >
                      <v-icon size="18">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Löschen</span>
                </v-tooltip>
              </template>
              <template v-else>
                <info-popup title="Hinweis: Zielgruppe gesperrt">
                  <p>
                    Die von Ihnen ausgewählte Zielgruppe ist derzeit gesperrt,
                    da es in einer aktiven Kampagne verwendet wird.
                  </p>
                  <p>
                    Änderungen an dieser Zielgruppe sind nicht möglich,
                    solange die Kampagne nicht abgeschlossen, storniert
                    oder aufgrund eines Fehlers abgebrochen wurde.
                  </p>
                  <p>
                    Sobald die Kampagne ihren endgültigen Status erreicht hat,
                    wird die Zielgruppe wieder zur Bearbeitung freigegeben.
                  </p>
                </info-popup>
              </template>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiBullseyeArrow,
  mdiDownload,
  mdiAlertCircleOutline,
  mdiFilePdf,
  mdiVectorSquare,
  mdiPlus,
  mdiCloudUpload,
  mdiClose,
  mdiContentCopy,
  mdiNumeric,
  mdiShareVariantOutline
} from '@mdi/js'
import axios from "axios";
import {getCurrentInstance, ref} from "@vue/composition-api";
import { avatarText } from '@core/utils/filter'
import InlineDatePicker from "@/views/InlineDatePicker.vue";
import AvvContract from "@/views/AvvContract.vue";
import InfoPopup from "@/views/InfoPopup.vue";
import router from '@/router'
import StatisticsCardWithImages from "@core/components/statistics-card/StatisticsCardWithImages";
import themeConfig from '@themeConfig'


export default {
  name: 'channel-list',
  components: {
    AvvContract,
    InfoPopup,
    InlineDatePicker,
    StatisticsCardWithImages
  },
  setup() {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const ratingsOptions = {
      statTitle: 'Zielgruppen',
      statistics: '13k',
      change: '+38%',
      chipText: 'Gesamt',
      chipColor: 'error',
      avatar: require('@/assets/images/nitramit/2024-02-26-icon-zielgruppen-blau.png'),
      avatarWidth: '111',

    }

    const userData = JSON.parse(localStorage.getItem('userData'));

    return {
      vm,
      router,
      ratingsOptions,
      userData
    }
  },
  data() {
    return {
      avatarText:avatarText,
      actions: ['Delete', 'Edit'],
      icons: {
        mdiDownload,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiBullseyeArrow,
        mdiNumeric,
        mdiCloudUpload,
        mdiFilePdf,
        mdiVectorSquare,
        mdiShareVariantOutline,
        mdiPlus,
        mdiClose,
        mdiContentCopy,
        mdiCheckCircleOutline,
      },
      filter: { searchString: '', status: 'offene',datumVon:'',datumBis:''},
      loading: false,
      totalAmountList: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['idPtZielgruppe'],
        sortDesc: [true],
      },
      headers: [
        {
          text: '#',
          align: 'start',
          value: 'idPtZielgruppe',
        },
        {text: 'Titel', value: 'titel'},
        {text: 'Datenquelle', value: 'datenquelleDisplayName'},
        {text: 'Anzahl Empfänger', value: 'anzahlEmpfaenger'},
        {text: 'zuletzt Bearbeitet', value: 'zeitBearbeitungDE'},
        {text: 'Freigegeben für Dritte', value: 'freigabeCode'},
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      listTargetGroups: [],
      dialogDelete: false,
      dialogEnterReleaseCode: false,
      dialogRelease: false,
      dialogAvv: false,
      dialogEdit: false,
      deleteItem: {
        idPtZielgruppe: 0,
        name: '',
      },
      releaseCode: "",
      releaseItem: {
        idPtZielgruppe: 0,
        freigabeCode: '',
      },
      editIndex: -1,
      editValid : false,
      editItem: {
        idPtZielgruppe: 0,
        name: '',
      },
      defaultItem: {
        idPtZielgruppe: 0,
        name: '',
      },
      editValidationError : true,
      errorMessages : {
        releaseCode:"",
      },

    }
  },
  mounted() {
    this.fetchTargetGroups()
  },
  watch: {
    options: {
      handler() {
        this.fetchTargetGroups()
      },
    },
    filter: {
      handler() {
        this.fetchTargetGroups()
      },
      deep: true,
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogEnterReleaseCode (val) {
      val || this.closeEnterReleaseCode()
    },
    dialogRelease (val) {
      val || this.closeRelease()
    },
    dialogAvv (val) {
      val || this.closeAvv()
    },
    dialogEdit (val) {
      val || this.closeEdit()
    },
  },
  computed: {
    formTitle () {
      return this.editIndex === -1 ? 'Zielgruppe erstellen' : 'Zielgruppe bearbeiten'
    },
  },
  methods: {
    fetchTargetGroups(targetGroup) {
      this.loading = true
      axios.post('api/zielgruppe/', {
        aktion: 'showOverviewTargetGroups',
        filter: this.filter,
        options: encodeURIComponent(JSON.stringify(this.options)),
        highlightTargetGroup: targetGroup,
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.listTargetGroups = response.data.targetGroups || []
            this.totalAmountList = parseInt(response.data.total) || 0
            this.loading = false
          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteTargetGroup (item) {
      this.dialogDelete = true
      this.deleteItem = JSON.parse(JSON.stringify(item))
    },
    enterReleaseCodeTargetGroup () {
      this.dialogEnterReleaseCode = true
    },
    releaseTargetGroup (item) {
      this.dialogRelease = true
      this.releaseItem = JSON.parse(JSON.stringify(item))

      if(!this.releaseItem.freigabeCode){
        axios.post('api/zielgruppe/', {
          aktion: 'generateReleaseCode',
          mainData :{idPtZielgruppe: this.releaseItem.idPtZielgruppe},
        })
          .then((response) => {
            if(response.data.valid){
              this.releaseItem.freigabeCode = response.data.freigabeCode
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }

    },
    copyReleaseCode() {
      this.$clipboard(this.releaseItem.freigabeCode);
      this.vm.$toastr.s('Code Kopiert');
    },
    openAvvDialog (item) {
      this.dialogAvv = true
    },
    deleteTargetGroupConfirm () {
      axios.post('api/zielgruppe/', {
        aktion: 'deleteTargetGroup',
        mainData :{idPtZielgruppe: this.deleteItem.idPtZielgruppe},
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchTargetGroups()
        })
        .catch((error) => {
          console.log(error)
        })

      this.closeDelete()
    },
    enterReleaseCodeTargetGroupConfirm () {
      axios.post('api/zielgruppe/', {
        aktion: 'enterReleaseCode',
        mainData :{releaseCode: this.releaseCode},
      })
        .then((response) => {

          if(response.data.valid){

            this.vm.$toastr.s(response.data.message.title, response.data.message.text);
            this.fetchTargetGroups(response.data.targetGroup)
            this.releaseCode = "";
            this.closeEnterReleaseCode()
          }
          else{
            response.data.errors.forEach(item => {
              this.errorMessages[item.errorField] = item.errorTitle;
            })
          }

        })
        .catch((error) => {
          console.log(error)
        })


    },
    closeDelete () {
      this.dialogDelete = false
    },
    closeEnterReleaseCode () {
      this.dialogEnterReleaseCode = false
    },
    closeRelease () {
      this.dialogRelease = false
    },
    closeAvv () {
      this.dialogAvv = false
    },
    editTargetGroup (item) {
      this.editValidationError = false
      this.dialogEdit = true
      if(item) {
        this.editIndex = this.listTargetGroups.indexOf(item)
        this.editItem = Object.assign({}, item)
      }
    },
    titleRules (v) {
      this.editValidationError = true
      if(!v) {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      else if((v || '').length < 2) {
        return 'Der Titel muss mindetens 2 Zeichen besitzen.';
      }
      else if((v || '').length > 45) {
        return 'Der Titel kann maximal 45 Zeichen lang sein.';
      }

      this.editValidationError = false
      return true
    },

    editTargetGroupConfirm () {
      axios.post('api/zielgruppe/', {
        aktion: this.editIndex === -1 ? 'createTargetGroupCheck' : 'editTargetGroupCheck',
        mainData: this.editItem,
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.vm.$toastr.s(response.data.message.title, response.data.message.text);
            this.fetchTargetGroups()
          }
          else {
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              this.vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          this.closeEdit()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    closeEdit () {
      this.$nextTick(() => {
        this.dialogEdit = false
        this.editIndex = -1
        this.editItem = this.defaultItem
      })
    },
    updateFilter (key,value) {
      this.$nextTick(() => {
        this.filter[key] = value;
      })
    },
    generateReleaseCode(){
      alert("share")
    },
    highlightRow(item){

      if(item.highlight){
        return 'highlighted-success';
      }
      if(item.angabeGeloescht){
        return 'highlighted-error';
      }
      return '';
    }
  }
}
</script>

<style lang="scss" >
.v-card__title {
  word-break: normal;
}
#channel-list {
  .channel-list-search {
    max-width: 10.625rem;
  }
}

.highlighted-success, .highlighted-success td {
  background-color: #97fda7 !important;
}

.highlighted-error, .highlighted-error td {
  background-color: #fbbcbc !important;
}

</style>
