<template>
  <div class="">

    <template v-if="!accepted">
      <v-card-text
        class="scrollable-content mb-10 pl-5 pr-5"
        ref="contractText"
        @scroll="checkScroll"
      >

        <h2>Vertrag zur Auftragsverarbeitung gemäß Art. 28 DS-GVO</h2>

        <p>Zwischen der</p>

        <p class="primary--text">
          QUADRESS GmbH<br>
          Sophie-Opel-Str. 17<br>
          44803 Bochum<br>
        </p>
        <p>
          - Verantwortlicher - nachstehend Auftraggeber genannt -<br>
          und der
        </p>

        <p class="primary--text">
          {{userData.firma}}<br>
          {{userData.strasse}} {{userData.hausNr}}<br>
          {{userData.plz}} {{userData.ort}}<br>
        </p>

        <p>- Auftragsverarbeiter - nachstehend Auftragnehmer genannt</p>

        <h2 class="mt-5">Hinweis</h2>



        <h3 class="mt-10">1.Gegenstand und Dauer des Auftrags</h3>
        <ol>
          <li>Gegenstand</li>
          <p>Der Gegenstand des Auftrags ergibt sich aus der Nutzung der Software „mailVista“ und den dafür geltenden AGBs, auf die hier verwiesen wird (im Folgenden Leistungsvereinbarung).</p>
          <li>Dauer</li>
          <p>Die Dauer dieses Auftrags (Laufzeit) entspricht der Laufzeit der Leistungsvereinbarung.</p>
        </ol>

        <h3 class="mt-10">2.Konkretisierung des Auftragsinhalts</h3>
        <ol>
          <li>
            <h4>Art und Zweck der vorgesehenen Verarbeitung von Daten</h4>
            <p>Art und Zweck der Verarbeitung personenbezogener Daten durch den Auftragnehmer für den Auftraggeber ist die Anpassung, Speicherung und Weiterleitung der Daten an einen Lettershop für den Versand eines postalischen Werbemittels an diese Daten.</p>
            <p>Die Erbringung der vertraglich vereinbarten Datenverarbeitung findet ausschließlich in einem Mitgliedsstaat der Europäischen Union oder in einem anderen Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum statt. Jede Verlagerung in ein Drittland bedarf der vorherigen Zustimmung des Auftraggebers und darf nur erfolgen, wenn die besonderen Voraussetzungen der Artt. 44 ff. DS-GVO erfüllt sind.</p>
          </li>
          <li>
            <h4>Art der Daten</h4>
            <p>Gegenstand der Verarbeitung personenbezogener Daten sind folgende Datenarten/-kategorien:</p>
            <ul>
              <li>Personenstammdaten</li>
              <li>Unternehmensstammdaten</li>
            </ul>
          </li>
          <li>
            <h4>Kategorien betroffener Personen</h4>
            <p>Kategorien der durch die Verarbeitung betroffenen Personen umfassen:</p>
            <ul>
              <li>Kunden</li>
              <li>Interessenten</li>
              <li>Ansprechpartner</li>
            </ul>
          </li>
        </ol>



        <h3 class="mt-10">3.Technisch-organisatorische Maßnahmen</h3>
        <ol>
          <li>
            <h4>Dokumentation und Prüfung der Maßnahmen</h4>
            <p>Der Auftragnehmer hat die Umsetzung der im Vorfeld der Auftragsvergabe dargelegten und erforderlichen technischen und organisatorischen Maßnahmen vor Beginn der Verarbeitung, insbesondere hinsichtlich der konkreten Auftragsdurchführung zu dokumentieren und dem Auftraggeber zur Prüfung zu übergeben. Bei Akzeptanz durch den Auftraggeber werden die dokumentierten Maßnahmen Grundlage des Auftrags. Soweit die Prüfung/ein Audit des Auftraggebers einen Anpassungsbedarf ergibt, ist dieser einvernehmlich umzusetzen.</p>
          </li>
          <li>
            <h4>Sicherheitsmaßnahmen gemäß DS-GVO</h4>
            <p>Der Auftragnehmer hat die Sicherheit gemäß Artt. 28 Abs. 3 lit. c, 32 DS-GVO insbesondere in Verbindung mit Art. 5 Abs. 1, Abs. 2 DS-GVO herzustellen. Insgesamt handelt es sich bei den zu treffenden Maßnahmen um Maßnahmen der Datensicherheit und zur Gewährleistung eines dem Risiko angemessenen Schutzniveaus hinsichtlich der Vertraulichkeit, der Integrität, der Verfügbarkeit sowie der Belastbarkeit der Systeme. Dabei sind der Stand der Technik, die Implementierungskosten und die Art, der Umfang und die Zwecke der Verarbeitung sowie die unterschiedliche Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten natürlicher Personen im Sinne von Art. 32 Abs. 1 DS-GVO zu berücksichtigen <a href="#">[Einzelheiten in Anlage 1]</a>.</p>
          </li>
          <li>
            <h4>Fortentwicklung und Dokumentation</h4>
            <p>Die technischen und organisatorischen Maßnahmen unterliegen dem technischen Fortschritt und der Weiterentwicklung. Insoweit ist es dem Auftragnehmer gestattet, alternative adäquate Maßnahmen umzusetzen. Dabei darf das Sicherheitsniveau der festgelegten Maßnahmen nicht unterschritten werden. Wesentliche Änderungen sind zu dokumentieren.</p>
          </li>
        </ol>



        <h3 class="mt-10">4.Berichtigung, Einschränkung und Löschung von Daten</h3>
        <ol>
          <li>
            <h4>Weisungsgebundene Datenverarbeitung</h4>
            <p>Der Auftragnehmer darf die Daten, die im Auftrag verarbeitet werden, nicht eigenmächtig sondern nur nach dokumentierter Weisung des Auftraggebers berichtigen, löschen oder deren Verarbeitung einschränken. Soweit eine betroffene Person sich diesbezüglich unmittelbar an den Auftragnehmer wendet, wird der Auftragnehmer dieses Ersuchen unverzüglich an den Auftraggeber weiterleiten.</p>
          </li>
          <li>
            <h4>Sicherstellung der Rechte betroffener Personen</h4>
            <p>Soweit vom Leistungsumfang umfasst, sind Löschkonzept, Recht auf Vergessenwerden, Berichtigung, Datenportabilität und Auskunft nach dokumentierter Weisung des Auftraggebers unmittelbar durch den Auftragnehmer sicherzustellen.</p>
          </li>
        </ol>



        <h3 class="mt-10">5.Qualitätssicherung und sonstige Pflichten des Auftragnehmers</h3>
        <p>Der Auftragnehmer hat zusätzlich zu der Einhaltung der Regelungen dieses Auftrags gesetzliche Pflichten gemäß Artt. 28 bis 33 DS-GVO; insofern gewährleistet er insbesondere die Einhaltung folgender Vorgaben:</p>
        <ol>
          <li>
            <h4>Schriftliche Bestellung eines Datenschutzbeauftragten</h4>
            <p>Der Auftragnehmer bestellt einen Datenschutzbeauftragten, der seine Tätigkeit gemäß Artt. 38 und 39 DS-GVO ausübt.</p>
          </li>
          <li>
            <h4>Wahrung der Vertraulichkeit</h4>
            <p>Der Auftragnehmer wahrt die Vertraulichkeit gemäß Artt. 28 Abs. 3 S. 2 lit. b, 29, 32 Abs. 4 DS-GVO. Er setzt bei der Durchführung der Arbeiten nur Beschäftigte ein, die auf die Vertraulichkeit verpflichtet und zuvor mit den für sie relevanten Bestimmungen zum Datenschutz vertraut gemacht wurden.</p>
          </li>
          <li>
            <h4>Weisungsgebundene Verarbeitung</h4>
            <p>Der Auftragnehmer und jede dem Auftragnehmer unterstellte Person, die Zugang zu personenbezogenen Daten hat, dürfen diese Daten ausschließlich entsprechend der Weisung des Auftraggebers verarbeiten, einschließlich der in diesem Vertrag eingeräumten Befugnisse, es sei denn, dass sie gesetzlich zur Verarbeitung verpflichtet sind.</p>
          </li>
          <li>
            <h4>Umsetzung technischer und organisatorischer Maßnahmen</h4>
            <p>Die Umsetzung und Einhaltung aller für diesen Auftrag erforderlichen technischen und organisatorischen Maßnahmen gemäß Artt. 28 Abs. 3 S. 2 lit. c, 32 DS-GVO.</p>
          </li>
          <li>
            <h4>Zusammenarbeit mit Aufsichtsbehörden</h4>
            <p>Auf Anfrage arbeiten der Auftraggeber und der Auftragnehmer mit der Aufsichtsbehörde bei der Erfüllung ihrer Aufgaben zusammen.</p>
          </li>
          <li>
            <h4>Information des Auftraggebers über Kontrollhandlungen und Maßnahmen der Aufsichtsbehörde</h4>
            <p>Der Auftragnehmer informiert den Auftraggeber unverzüglich über Kontrollhandlungen und Maßnahmen der Aufsichtsbehörde, soweit sie sich auf diesen Auftrag beziehen.</p>
          </li>
          <li>
            <h4>Unterstützung des Auftraggebers</h4>
            <p>Soweit der Auftraggeber seinerseits einer Kontrolle der Aufsichtsbehörde, einem Ordnungswidrigkeits- oder Strafverfahren, dem Haftungsanspruch einer betroffenen Person oder eines Dritten oder einem anderen Anspruch im Zusammenhang mit der Auftragsverarbeitung beim Auftragnehmer ausgesetzt ist, hat ihn der Auftragnehmer nach besten Kräften zu unterstützen.</p>
          </li>
          <li>
            <h4>Kontrolle der internen Prozesse</h4>
            <p>Der Auftragnehmer kontrolliert regelmäßig die internen Prozesse sowie die technischen und organisatorischen Maßnahmen, um zu gewährleisten, dass die Verarbeitung in seinem Verantwortungsbereich im Einklang mit den Anforderungen des geltenden Datenschutzrechts erfolgt und der Schutz der Rechte der betroffenen Person gewährleistet wird.</p>
          </li>
          <li>
            <h4>Nachweisbarkeit der getroffenen Maßnahmen</h4>
            <p>Nachweisbarkeit der getroffenen technischen und organisatorischen Maßnahmen gegenüber dem Auftraggeber im Rahmen seiner Kontrollbefugnisse nach Ziffer 7 dieses Vertrages.</p>
          </li>
        </ol>



        <h3 class="mt-10">6.Unterauftragsverhältnisse</h3>
        <ol>
          <li>
            <h4>Definition von Unterauftragsverhältnissen</h4>
            <p>Als Unterauftragsverhältnisse im Sinne dieser Regelung sind solche Dienstleistungen zu verstehen, die sich unmittelbar auf die Erbringung der Hauptleistung beziehen. Nicht hierzu gehören Nebenleistungen, die der Auftragnehmer z.B. als Telekommunikationsleistungen, Post-/Transportdienstleistungen, Wartung und Benutzerservice oder die Entsorgung von Datenträgern sowie sonstige Maßnahmen zur Sicherstellung der Vertraulichkeit, Verfügbarkeit, Integrität und Belastbarkeit der Hard- und Software von Datenverarbeitungsanlagen in Anspruch nimmt. Der Auftragnehmer ist jedoch verpflichtet, zur Gewährleistung des Datenschutzes und der Datensicherheit der Daten des Auftraggebers auch bei ausgelagerten Nebenleistungen angemessene und gesetzeskonforme vertragliche Vereinbarungen sowie Kontrollmaßnahmen zu ergreifen.</p>
          </li>
          <li>
            <h4>Zustimmung zur Beauftragung von Unterauftragnehmern</h4>
            <p>Der Auftragnehmer darf Unterauftragnehmer (weitere Auftragsverarbeiter) nur nach vorheriger ausdrücklicher schriftlicher bzw. dokumentierter Zustimmung des Auftraggebers beauftragen.</p>
          </li>
          <li>
            <h4>Weitergabe von personenbezogenen Daten</h4>
            <p>Die Weitergabe von personenbezogenen Daten des Auftraggebers an den Unterauftragnehmer und dessen erstmaliges Tätigwerden sind erst mit Vorliegen aller Voraussetzungen für eine Unterbeauftragung gestattet.</p>
          </li>
          <li>
            <h4>Erbringung der Leistung außerhalb der EU/des EWR</h4>
            <p>Erbringt der Unterauftragnehmer die vereinbarte Leistung außerhalb der EU/des EWR, stellt der Auftragnehmer die datenschutzrechtliche Zulässigkeit durch entsprechende Maßnahmen sicher. Gleiches gilt, wenn Dienstleister im Sinne von Abs. 1 Satz 2 eingesetzt werden sollen.</p>
          </li>
        </ol>



        <h3 class="mt-10">7.Kontrollrechte des Auftraggebers</h3>
        <ol>
          <li>
            <h4>Durchführung von Überprüfungen</h4>
            <p>Der Auftraggeber hat das Recht, im Benehmen mit dem Auftragnehmer Überprüfungen durchzuführen oder durch im Einzelfall zu benennende Prüfer durchführen zu lassen. Er hat das Recht, sich durch Stichprobenkontrollen, die in der Regel rechtzeitig anzumelden sind, von der Einhaltung dieser Vereinbarung durch den Auftragnehmer in dessen Geschäftsbetrieb zu überzeugen.</p>
          </li>
          <li>
            <h4>Auskunfts- und Nachweispflicht des Auftragnehmers</h4>
            <p>Der Auftragnehmer stellt sicher, dass sich der Auftraggeber von der Einhaltung der Pflichten des Auftragnehmers nach Art. 28 DS-GVO überzeugen kann. Der Auftragnehmer verpflichtet sich, dem Auftraggeber auf Anforderung die erforderlichen Auskünfte zu erteilen und insbesondere die Umsetzung der technischen und organisatorischen Maßnahmen nachzuweisen.</p>
          </li>
          <li>
            <h4>Nachweis von Maßnahmen</h4>
            <p>Der Nachweis solcher Maßnahmen, die nicht nur den konkreten Auftrag betreffen, kann erfolgen durch:</p>
            <ul>
              <li>die Einhaltung genehmigter Verhaltensregeln gemäß Art. 40 DS-GVO;</li>
              <li>die Zertifizierung nach einem genehmigten Zertifizierungsverfahren gemäß Art. 42 DS-GVO;</li>
              <li>aktuelle Testate, Berichte oder Berichtsauszüge unabhängiger Instanzen (z.B. Wirtschaftsprüfer, Revision, Datenschutzbeauftragter, IT-Sicherheitsabteilung, Datenschutzauditoren, Qualitätsauditoren);</li>
              <li>eine geeignete Zertifizierung durch IT-Sicherheits- oder Datenschutzaudit (z.B. nach BSI-Grundschutz).</li>
            </ul>
          </li>
          <li>
            <h4>Vergütungsanspruch des Auftragnehmers</h4>
            <p>Für die Ermöglichung von Kontrollen durch den Auftraggeber kann der Auftragnehmer einen Vergütungsanspruch geltend machen.</p>
          </li>
        </ol>



        <h3 class="mt-10">8.Mitteilung bei Verstößen des Auftragnehmers</h3>
        <ol>
          <li>
            <h4>Unterstützung des Auftraggebers</h4>
            <p>Der Auftragnehmer unterstützt den Auftraggeber bei der Einhaltung der in den Artikeln 32 bis 36 der DS-GVO genannten Pflichten zur Sicherheit personenbezogener Daten, Meldepflichten bei Datenpannen, Datenschutz-Folgeabschätzungen und vorherige Konsultationen. Hierzu gehören u.a.:</p>
            <ul>
              <li>die Sicherstellung eines angemessenen Schutzniveaus durch technische und organisatorische Maßnahmen, die die Umstände und Zwecke der Verarbeitung sowie die prognostizierte Wahrscheinlichkeit und Schwere einer möglichen Rechtsverletzung durch Sicherheitslücken berücksichtigen und eine sofortige Feststellung von relevanten Verletzungsereignissen ermöglichen</li>
              <li>die Verpflichtung, Verletzungen personenbezogener Daten unverzüglich an den Auftraggeber zu melden</li>
              <li>die Verpflichtung, dem Auftraggeber im Rahmen seiner Informationspflicht gegenüber dem Betroffenen zu unterstützen und ihm in diesem Zusammenhang sämtliche relevante Informationen unverzüglich zur Verfügung zu stellen</li>
              <li>die Unterstützung des Auftraggebers für dessen Datenschutz-Folgenabschätzung</li>
              <li>die Unterstützung des Auftraggebers im Rahmen vorheriger Konsultationen mit der Aufsichtsbehörde</li>
            </ul>
          </li>
          <li>
            <h4>Vergütungsanspruch des Auftragnehmers</h4>
            <p>Für Unterstützungsleistungen, die nicht in der Leistungsbeschreibung enthalten oder nicht auf ein Fehlverhalten des Auftragnehmers zurückzuführen sind, kann der Auftragnehmer eine Vergütung beanspruchen.</p>
          </li>
        </ol>



        <h3 class="mt-10">9.Weisungsbefugnis des Auftraggebers</h3>
        <ol>
          <li>
            <p>Mündliche Weisungen bestätigt der Auftraggeber unverzüglich (mind. Textform).</p>
          </li>
          <li>
            <p>Der Auftragnehmer hat den Auftraggeber unverzüglich zu informieren, wenn er der Meinung ist, eine Weisung verstoße gegen Datenschutzvorschriften. Der Auftragnehmer ist berechtigt, die Durchführung der entsprechenden Weisung solange auszusetzen, bis sie durch den Auftraggeber bestätigt oder geändert wird.</p>
          </li>
        </ol>



        <h3 class="mt-10">10.Löschung und Rückgabe von personenbezogenen Daten</h3>
        <ol>
          <li>
            <p>Kopien oder Duplikate der Daten werden ohne Wissen des Auftraggebers nicht erstellt. Hiervon ausgenommen sind Sicherheitskopien, soweit sie zur Gewährleistung einer ordnungsgemäßen Datenverarbeitung erforderlich sind, sowie Daten, die im Hinblick auf die Einhaltung gesetzlicher Aufbewahrungspflichten erforderlich sind.</p>
          </li>
          <li>
            <p>Nach Abschluss der vertraglich vereinbarten Arbeiten oder früher nach Aufforderung durch den Auftraggeber – spätestens mit Beendigung der Leistungsvereinbarung – hat der Auftragnehmer sämtliche in seinen Besitz gelangten Unterlagen, erstellte Verarbeitungs- und Nutzungsergebnisse sowie Datenbestände, die im Zusammenhang mit dem Auftragsverhältnis stehen, dem Auftraggeber auszuhändigen oder nach vorheriger Zustimmung datenschutzgerecht zu vernichten. Gleiches gilt für Test- und Ausschussmaterial. Das Protokoll der Löschung ist auf Anforderung vorzulegen.</p>
          </li>
          <li>
            <p>Dokumentationen, die dem Nachweis der auftrags- und ordnungsgemäßen Datenverarbeitung dienen, sind durch den Auftragnehmer entsprechend der jeweiligen Aufbewahrungsfristen über das Vertragsende hinaus aufzubewahren. Er kann sie zu seiner Entlastung bei Fristende dem Auftraggeber übergeben.</p>
          </li>
        </ol>



        <h3 class="mt-10">Anlage 1: Technisch-organisatorische Maßnahmen des Auftragsverarbeiters (TOMs)</h3>
        <p>Beschreibung der technischen und organisatorischen Maßnahmen der QUADRESS GmbH</p>
        <p>Angaben zum Verantwortlichen (Art. 30 Abs. 1 lit. a DSGVO):</p>
        <ul>
          <li>Verantwortlicher:
            <address>
              QUADRESS GmbH<br>
              Sophie-Opel-Str. 17<br>
              44803 Bochum<br>
              Deutschland
            </address>
          </li>
          <li>Gesetzlicher Vertreter: Daniel Simon</li>
          <li>Datenschutzbeauftragter:
            <address>
              zb Datenschutz u. -sicherheit GmbH & Co. KG<br>
              Herr Ulrich Braunbach<br>
              Hansestr. 79<br>
              51149 Köln
            </address>
          </li>
        </ul>



        <h3 class="mt-10">M.1 Maßnahmen zur Vertraulichkeit</h3>

        <h3>M.1.1 Beschreibung der Zutrittskontrolle:</h3>
        <ul>
          <li>Alarmanlage - Alarmanlage mit Information über Auslösen an Wachdienst</li>
          <li>Alarmanlage Serverraum - Alarmanlage mit Information über Auslösen an Wachdienst</li>
          <li>Betriebsfremde Personen - werden am Eingang bzw. Empfang vom Ansprechpartner abgeholt und dürfen sich im Gebäude nur begleitet bewegen</li>
          <li>Pförtner - Personenkontrolle beim Pförtner</li>
          <li>RFID - Zugangssperre durch RFID</li>
          <li>Schließanlage - Gebäude ist elektronisch verschlossen, nicht die Eingänge zu den Büros und/oder Büroetagen; Zutrittsrechte personifiziert: ja; Protokollierung von erfolgreichen Zutritten und erfolglosen Zutrittsversuchen; Protokolldaten werden für die Dauer von 6 Wochen aufbewahrt; regelmäßige Auswertung Protokolle: nein - eine Auswertung ist im Bedarfsfall möglich</li>
          <li>Schließanlage Serverraum - Zutrittsrechte personifiziert: ja; Protokollierung von erfolgreichen Zutritten und erfolglosen Zutrittsversuchen; Protokolldaten werden für die Dauer von 6 Wochen aufbewahrt; regelmäßige Auswertung Protokolle: nein - eine Auswertung ist im Bedarfsfall möglich</li>
          <li>Schlüsselverwaltung Serverraum - Anzahl Schlüssel: 2; Aufbewahrungsort: bei den berechtigten Personen; Ausgabestelle: Geschäftsführung</li>
          <li>Serverraum fensterlos - ja</li>
          <li>Videoüberwachung - Bürogebäude und/oder Zugänge werden ohne Bildaufzeichnung videoüberwacht</li>
          <li>Zutrittsberechtigte Personen Serverraum - Anzahl Personen: 2; Funktion im Unternehmen: Geschäftsführer, IT-Dienstleister</li>
        </ul>

        <h3 class="mt-10">M.1.2 Beschreibung der Zugangskontrolle:</h3>
        <ul>
          <li>Authentifikation - Authentifikation mit Benutzer + Passwort</li>
          <li>Passwortlichtlinien - (Mindest-) Passwort-Zeichenlänge: 8; Sonderzeichen: ja; IT-System zwingt zur Einhaltung: ja; Maßnahmen bei Verlust: neues Initialpasswort durch Administration; Begrenzung von erfolglosen Anmeldeversuchen: ja nach 10 Versuchen; Zugang bleibt bis zur manuellen Aufhebung der Sperre durch Administrator gesperrt</li>
          <li>Authentifizierung bei Fernzugängen - Authentifizierung mit verschlüsseltem VPN und Passwort; Begrenzung erfolglose Anmeldeversuche: ja - nach 10 Versuchen; Zugang bleibt bis zur manuellen Aufhebung gesperrt; Verwaltung der Schlüssel bzw. Zertifikate: externer Dienstleister; Übertragungsvorgänge werden protokolliert; Protokolldaten werden für 3 Monate aufbewahrt; Protokolle generell nicht regelmäßig ausgewertet. Eine Auswertung ist im Bedarfsfall möglich</li>
          <li>Automatische Bildschirmsperre - Sperre nach 5 Minuten</li>
          <li>Benutzerberechtigungen - Bei Vergabe von Benutzerkennungen und Zugriffsberechtigungen bei Neueinstellung und Ausscheiden von Mitarbeitern sowie bei organisatorischen Veränderungen existiert ein definierter Freigabeprozess. Die Vergabe und/oder Änderungen wird protokolliert</li>
          <li>Firewall - Regelmäßige Updates: ja; Administration: externer Dienstleister</li>
        </ul>



        <h3 class="mt-10">M.1.3 Beschreibung der Zugriffskontrolle:</h3>
        <ul>
          <li>Berechtigungskonzept - Einsatz eines rollenbasierten Berechtigungskonzepts</li>
          <li>Entsorgung Datenträger - Physikalische Zerstörung durch eigene IT und externen Dienstleister; Löschen der Daten</li>
          <li>Entsorgung Papierunterlagen - Entsorgung durch Schredder (der Schutzklasse und der Sicherheitsstufe nach DIN 66399)</li>
          <li>Mobile Datenträger - Verschlüsselung von mobilen Datenträgern (inkl. Laptops) durch Verschlüsselung der Festplatte</li>
          <li>Private Datenträger oder Endgeräte - Private Datenträger dürfen generell nur nach Genehmigung und Überprüfung des Speichermediums durch die IT verwendet werden. Auf privaten Geräten werden keine personenbezogenen Daten verarbeitet</li>
        </ul>



        <h3 class="mt-10">M.1.4 Beschreibung der Weitergabekontrolle:</h3>
        <ul>
          <li>SFTP - Einsatz von SFTP</li>
          <li>SSL / TLS Verschlüsselung - Einsatz von SSL-/TLS-Verschlüsselung bei der Datenübertragung im Internet; Verwaltung Schlüssel bzw. Zertifikate: externer Dienstleister; Übertragungsvorgänge werden protokolliert; Protokolle werden für 3 Monate aufbewahrt; Protokolle werden generell nicht ausgewertet. Eine Auswertung ist im Bedarfsfall möglich.</li>
          <li>E-Mails: Anhänge mit schützenswerten Daten werden nur verschlüsselt verschickt. Passwort zum Entschlüsseln wird per SMS oder Fax versendet.</li>
          <li>Internes Softwaresystem mit verschlüsselter Cloud für Datenaustausch mit Loginbereich</li>
        </ul>



        <h3 class="mt-10">M.1.5 Beschreibung des Trennungsgebots:</h3>
        <ul>
          <li>Logische Mandantentrennung - Logische Mandantentrennung (softwareseitig)</li>
        </ul>



        <h3 class="mt-10">M.1.6 Beschreibung der Pseudonymisierung:</h3>
        <ul>
          <li>Verwendung von Kennziffern für Kunden anstatt Namen</li>
          <li>Zuordnungstabellen können nur durch Mitarbeiter der höchsten Sicherheitsstufe (laut Berechtigungskonzept) eingesehen werden.</li>
        </ul>



        <h3 class="mt-10">M.2 Maßnahmen zur Integrität:</h3>



        <h3>M.2.1 Beschreibung der Eingabekontrolle:</h3>
        <ul>
          <li>Nachvollziehbarkeit von Eingabe, Änderung und Löschung von Daten durch individuelle Benutzernamen (nicht Benutzergruppen)</li>
          <li>Protokollierung der Eingabe, Änderung und Löschung von Daten. Aufbewahrungszeit der Protokolle kann individuell im System eingestellt werden.</li>
          <li>Personenbezogene Zugriffsrechte zur Nachvollziehbarkeit der Zugriffe</li>
        </ul>




        <h3 class="mt-10">M.3 Maßnahmen zur Verfügbarkeit und Belastbarkeit:</h3>



        <h3 class="mt-10">M.3.1 Beschreibung der Verfügbarkeitskontrolle:</h3>
        <ul>
          <li>Einsatz von Virenschutz, Anti-Spyware und Spamfilter; Aktualisierung durch externen Dienstleister</li>
          <li>Feuer- und Rauchmeldeanlagen</li>
          <li>Zugangstür des Serverraums ist feuerfest/feuerhemmend; Rauchmelder vorhanden; Anschluss an Brandmeldezentrale vorhanden; CO2 Löscher als Löschsystem; Massivwände; regelmäßige Tests auf Funktionalität; Geräte zur Überwachung von Temperatur vorhanden</li>
          <li>Redundante Firewall im Cold Standby</li>
          <li>Klimaanlage im Serverraum vorhanden; Geräte zur Überwachung von Temperatur und/oder Feuchtigkeit; jährliche Wartung</li>
          <li>Redundante Internetverbindung vorhanden, Verantwortlich: externer Dienstleister</li>
          <li>USV für die Stromversorgung im Serverraum; Funktionalität wird regelmäßig getestet, Test der Anlage im Livebetrieb zweimal pro Jahr</li>
          <li>Prozess zum Software- und Patchmanagement existiert und ist nicht dokumentiert; Verantwortlich: Anwender selbst, externer Dienstleister</li>
          <li>Geräte zur Überwachung von Temperatur und Feuchtigkeit in Serverräumen vorhanden</li>
        </ul>


        <h3 class="mt-10">M.3.2 Beschreibung der raschen Wiederherstellbarkeit:</h3>
        <ul>
          <li>Funktionalität des Backupkonzepts wird regelmäßig getestet; Backups werden täglich durchgeführt; Vollsicherung wird verwendet; Sicherungsmedien umfassen einen zweiten redundanten Server und Festplatten; Aufbewahrungsort der Backups: im Serverraum; Backups sind verschlüsselt; Aufbewahrungsort in getrennten Brandabschnitten: nein</li>
          <li>Notfallkonzept bei Hardwaredefekt, Brand und Totalverlust</li>
        </ul>

        <h3 class="mt-10">M.4 Weitere Maßnahmen zum Datenschutz:</h3>

        <h3>M.4.1 Beschreibung der Auftragskontrolle:</h3>
        <ul>
          <li>Abschluss einer Vereinbarung zur Auftragsverarbeitung gemäß Art. 28 DSGVO</li>
        </ul>

        <h3 class="mt-10">M.4.2 Beschreibung des Managementsystems zum Datenschutz:</h3>
        <ul>
          <li>Einsatz von selbst entwickelter Software-Lösung mit datenschutzfreundlichen Voreinstellungen gemäß Art. 25 Abs. 2 DSGVO</li>
          <li>Incident-Response-System zur Nachvollziehbarkeit von Sicherheitsverstößen und Problemen</li>
          <li>Managementsystem zum Datenschutz (z.B. in Anlehnung an VdS 10010)</li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="acceptAvvContract"
          :disabled="!acceptEnabled"
        >
          Akzeptieren
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          @click="closeAvv"
        >
          Abbrechen
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </template>
    <template v-else>

      <v-card-text class="d-flex flex-column justify-center  align-center pb-5">

<!--        <span class="mb-10 success&#45;&#45;text">Sie haben den AV-Vertrag erfolgreich akzeptiert!</span>-->

        <router-link :to="'/zielgruppe/hochladen'" >
          <v-btn
            color="primary"
            class="ma-3"
          >
            <v-icon size="30" class="pr-2">{{icons.mdiCloudUpload }}</v-icon>
            <span>Eigene Adressen hochladen</span>
          </v-btn>
        </router-link>

        <a
          :href="`/api/verwaltung/?aktion=downloadAvvContractAsPdf`"
          :download="`avv.pdf`"
        >
          <v-btn
            color="primary"
            class="ma-3"
          >
            <v-icon size="30" class="pr-2">{{icons.mdiFilePdfOutline }}</v-icon>
            <span>AV-Vertrag herunterladen</span>
          </v-btn>

        </a>
      </v-card-text>

    </template>



  </div>
</template>

<script>

import {getCurrentInstance, ref} from '@vue/composition-api'
import themeConfig from "@themeConfig";
import axios from "axios";
import {
  mdiCloudUpload,
  mdiFilePdfOutline,
} from "@mdi/js";


export default {
  setup(props,{emit}) {

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const acceptEnabled = ref(false);
    const accepted = ref(false);
    const userData = JSON.parse(localStorage.getItem('userData'));

    const closeAvv = () => {
      acceptEnabled.value = false;
      emit("closeAvv");
    }

    const acceptAvvContract = () => {

      const html = `
        <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
        <html>
        <head>
            <title>AV-Vertrag</title>
            <style type="text/Css">
                body{font-family: "arial";font-size: 3mm;}
            </style>
        </head>
        <body>${vm.$refs.contractText.innerHTML}%suffix%</body>
        </html>
      `;

      axios.post('api/verwaltung/', {
        aktion: 'acceptAvvContract',
        html: html,
      })
        .then((response) => {
          if(response.data.valid){
            accepted.value = true;
          }
          else{
            response.data.errors.forEach(item => {
              let suffix = item.errorField != "" ? "(" + item.errorField + ")" : "";
              this.vm.$toastr.e(item.errorText, item.errorTitle + suffix);
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    const checkScroll = () => {
      const contractText = vm.$refs.contractText;
      if (contractText.scrollTop === (contractText.scrollHeight - contractText.offsetHeight)) {
        acceptEnabled.value = true;
      }
    }

    return {
      userData,
      acceptEnabled,
      accepted,
      acceptAvvContract,
      checkScroll,
      closeAvv,
      icons: {
        mdiCloudUpload,
        mdiFilePdfOutline
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.scrollable-content{
  max-height: 600px;
  overflow-y: auto;
}

</style>
